/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */

.ico-arrow-down {
    background-image: url(/sprite.png);
    background-position: 0% 100%;
    background-size: 681.8181818181819% 1236.3636363636363%;
    width: 22px;
    height: 11px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
}

.ico-search {
    background-image: url(/sprite.png);
    background-position: 0% 0%;
    background-size: 100% 112.39669421487604%;
    width: 150px;
    height: 121px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
}

@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 192dpi) {
    .ico-arrow-down {
        background-image: url(/sprite.@2x.png);
        background-position: 0% 100%;
        background-size: 681.8181818181819% 1236.3636363636363%;
        width: 22px;
        height: 11px;
        display: inline-block;
        vertical-align: middle;
        font-size: 0px;
    }
    .ico-search {
        background-image: url(/sprite.@2x.png);
        background-position: 0% 0%;
        background-size: 100% 112.39669421487604%;
        width: 150px;
        height: 121px;
        display: inline-block;
        vertical-align: middle;
        font-size: 0px;
    }
}

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
    min-width: 320px;
    background: #fff;
    font-family: Muli, sans-serif;
    font-size: 20px;
    line-height: 1.25;
    color: #1d1d1d;
}

a {
    color: inherit;
    text-decoration: underline;
}

a:hover,
a[href^='tel'] {
    text-decoration: none;
}

h1,
h2,
h3 {
    font-family: 'Alegreya', serif;
    font-weight: 500;
}

h4,
h5,
h6 {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
}

p {
    font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.625em;
}

h1 {
    font-size: 65px;
    line-height: 1.15;
}

h3 {
    font-size: 35px;
}

h4 {
    font-size: 25px;
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
    margin-bottom: 1.25em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
    margin-bottom: 0;
}

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after {
    content: '';
    display: block;
    clear: both;
}

/*  Notext  */

.notext {
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
}

/*  Hidden  */

[hidden],
.hidden {
    display: none !important;
}

/*  Alignleft  */

.alignleft {
    float: left;
}

/*  Alignright  */

.alignright {
    float: right;
}

/*  Disabled  */

[disabled],
.disabled {
    cursor: default;
}

/*  Grid  */

.cols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.col {
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
}

.col--1of2 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
}

/*  Responsive Helpers  */

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
    .visible-xs-block {
        display: block !important;
    }
    .visible-xs-inline {
        display: inline !important;
    }
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
    .visible-sm-block {
        display: block !important;
    }
    .visible-sm-inline {
        display: inline !important;
    }
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .hidden-md {
        display: none !important;
    }
    .visible-md-block {
        display: block !important;
    }
    .visible-md-inline {
        display: inline !important;
    }
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1201px) {
    .hidden-lg {
        display: none !important;
    }
    .visible-lg-block {
        display: block !important;
    }
    .visible-lg-inline {
        display: inline !important;
    }
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */

* {
    padding: 0;
    margin: 0;
    outline: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
    display: block;
}

template {
    display: none;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

img,
iframe,
video,
audio,
object {
    max-width: 100%;
}

img,
iframe {
    border: 0 none;
}

img {
    height: auto;
    display: inline-block;
    vertical-align: middle;
}

b,
strong {
    font-weight: bold;
}

address {
    font-style: normal;
}

svg:not(:root) {
    overflow: hidden;
}

a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='image'],
label[for] {
    cursor: pointer;
}

a[href^='tel'],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea,
a[href^='tel'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

textarea {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

button,
select {
    text-transform: none;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

nav ul,
nav ol {
    list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.container.open {
    position: relative;
    -webkit-transform: translateX(227px);
    -ms-transform: translateX(227px);
    transform: translateX(227px);
}

/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
    max-width: 1168px;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
}

.shell:after {
    content: '';
    clear: both;
    display: table;
    line-height: 0;
}

.shell--fluid {
    max-width: none;
}

@media (max-width: 767px) {
    .shell {
        padding-left: 22px;
        padding-right: 22px;
    }
}

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper .wrapper__head-margin {
    background-color: #e2e2e2;
    height: 20px;
    margin: 0;
    position: relative;
}

.wrapper .wrapper__half-fill {
    background-color: #f48120;
    position: absolute;
    width: 50%;
    height: 20px;
    top: 0px;
    right: 0px;
}
/* ------------------------------------------------------------ *\
	Modules
\* ------------------------------------------------------------ */
.wrapper {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.btn--default {
    min-width: 245px;
    height: 55px;
    padding: 0 5px;
    border: 0;
    border-radius: 5px;
    background: #f58220;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    line-height: 55px;
    -webkit-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
}

.btn--default:hover {
    background: #313131;
}

/*  Button Block  */

.btn--block {
    display: block;
    padding-left: 0;
    padding-right: 0;
}

/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
}

input:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

input::-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

input::placeholder {
    color: inherit;
    opacity: 1;
}

textarea::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
}

textarea:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

textarea::-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

textarea::placeholder {
    color: inherit;
    opacity: 1;
}

input:-webkit-autofill {
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
}

.intro h1 {
    margin-bottom: 15px;
    font-weight: 600;
}

.intro p {
    font-size: 25px;
}
.intro .intro__content {
    padding: 138px 0;
}

/* ------------------------------------------------------------ *\
	Intro Small
\* ------------------------------------------------------------ */

.intro--small .intro__content {
    padding: 87px 0;
}

.intro--small br {
    display: none;
}

@media (max-width: 991px) {
    .intro .intro__content {
        padding: 80px 0;
    }
    .intro h1 {
        font-size: 37px;
    }
    .intro p {
        font-size: 17px;
    }
}

@media (max-width: 767px) {
    .intro .intro__content {
        padding: 33px 0 71px;
    }
    .intro h1 {
        margin-bottom: 10px;
    }

    .intro .btn {
        min-width: 100%;
    }

    .intro--small .intro__content {
        padding: 16px 0 8px;
    }
    .intro--small br {
        display: block;
    }
    .intro--small h1 {
        font-size: 45px;
    }
}

/* ------------------------------------------------------------ *\
	Link
\* ------------------------------------------------------------ */

.link-more {
    color: #e16426;
    font-weight: 700;
}

.link-more--light {
    color: #fff;
}

/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^='list-'] {
    list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
    position: relative;
}

.section .section__backgrounds {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.section .section__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background: #f58220;
}

.section .section__background--light {
    right: auto;
    left: 0;
    background: #e2e2e2;
}

@media (max-width: 991px) {
    .section .section__backgrounds {
        display: none;
    }
}

/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */

.tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.tabs .tabs__head {
    position: relative;
    width: 23%;
    padding: 60px 0;
    background: #f58220;
}

.tabs .tabs__head:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 9999px;
    background: #f58220;
}

.tabs .tabs__body {
    width: 77%;
    padding: 66px 0 110px 82px;
}

.tabs .tabs__nav a {
    display: block;
    position: relative;
    padding: 10px 13px 10px 0;
    font-size: 22px;
    font-weight: 900;
    color: #313131;
    text-decoration: none;
    text-align: right;
}

.tabs .tabs__nav li + li {
    margin-top: 17px;
}

.tabs .tabs__nav a.hover:hover,
.tabs .tabs__nav .current a {
    background: #df6326;
    color: #fff;
}

.tabs .tabs__nav a.hover:hover:before,
.tabs .tabs__nav .current a:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 9999px;
    background: #df6326;
}

.tabs .tabs__nav a.hover:hover:after,
.tabs .tabs__nav .current a:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-left: 25px solid #df6326;
    border-bottom: 24px solid transparent;
}

.tab h3 {
    font-weight: 600;
}

.tab {
    display: none;
}

.tab.current {
    display: block;
}

@media (max-width: 1200px) {
    .tabs .tabs__nav a {
        font-size: 17px;
    }

    .tabs .tabs__nav a.hover:hover:after,
    .tabs .tabs__nav .current a:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-left: 21px solid #df6326;
        border-bottom: 21px solid transparent;
    }
}

@media (max-width: 991px) {
    .tabs {
        display: block;
        margin: 0 -10px;
    }

    .tabs .tabs__body,
    .tabs .tabs__head {
        width: 100%;
        padding: 20px 10px;
    }

    .tabs .tabs__head .select-menu {
        display: block;
        position: relative;
        width: 276px;
        height: 43px;
        padding: 0 39px 0 19px;
        margin: 0 auto;
        line-height: 43px;
        font-size: 17px;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        background: #df6326;
    }
    .tabs .tabs__head .select-menu i {
        position: absolute;
        top: 0;
        right: 14px;
        bottom: 0;
        margin: auto;
    }

    .tabs .tabs__nav {
        display: none;
        max-width: 276px;
        margin: 0 auto;
        border: 1px solid #df6326;
    }
    .tabs .tabs__nav a {
        text-align: center;
    }

    .tabs .tabs__nav li + li {
        margin-top: 0;
    }

    .tabs .tabs__nav a:hover:after,
    .tabs .tabs__nav .current a:after,
    .tabs .tabs__nav a:hover:before,
    .tabs .tabs__nav .current a:before {
        content: none;
    }
}

@media (max-width: 767px) {
    .tabs {
        margin: 0 -22px;
    }

    .tab h3 {
        font-size: 30px;
    }
    .tab p {
        margin-bottom: 21px;
        font-size: 17px;
        line-height: 1.12;
    }

    .tabs .tabs__body {
        padding: 27px 22px;
    }
    .tabs .tabs__head {
        padding: 20px 22px;
    }
}

/* ------------------------------------------------------------ *\
	Section Dark
\* ------------------------------------------------------------ */

.section--dark {
    background: #313131;
    color: #fff;
}

/* ------------------------------------------------------------ *\
	Section Contacts
\* ------------------------------------------------------------ */

.section--contacts {
    padding: 55px 0;
    text-align: center;
}

.section--contacts a {
    font-size: 25px;
    font-weight: 900;
}

@media (max-width: 767px) {
    .section--contacts {
        padding: 40px 0;
    }
    .section--contacts p {
        font-size: 17px;
    }
}

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widgets {
    list-style: none outside none;
}
